import * as $http from 'axios'

export default {
    all: () => $http.get('v2/refunds'),
    accept: refundId => $http.put(`v2/refunds/${refundId}/accept`),
    reject: (refundId, data) => $http.put(`v2/refunds/${refundId}/reject`, data),
    checked: (refundId, data) => $http.put(`v2/refunds/${refundId}/check`, data),
    targetUserRefundReason: (refundId, data) => $http.post(`v2/refunds/${refundId}/target_user_refund_reason`, data),
    refundIntroduce: chatId => $http.get(`v2/refunds/refund_introduce?chat_id=${chatId}`),
}
