<template>
    <div class="real-friend-meeting-refund-check">
        <StackRouterHeaderBar />

        <h1 class="title f-24 m-t-18 m-l-16">환급 신청 내용 확인</h1>

        <section class="section refund">
            <div class="refund-detail">
                <div class="m-b-32">
                    <p class="f-medium m-b-12 f-14">환급사유</p>
                    <p class="c-black f-15">{{ refund.description }}</p>
                </div>
                <div>
                    <p class="f-medium m-b-12 f-14">첨부자료</p>

                    <div v-if="refund" class="grid-col">
                        <div
                            class="img"
                            v-for="img in refund.images"
                            :key="img"
                            @click="onClickImage(img)"
                            v-lazy:background-image="img"
                        />
                    </div>
                </div>
            </div>
        </section>

        <hr class="hr" />

        <section class="section status">
            <h2 class="title f-16 m-b-20">
                친구와 {{ $nameOrNick(chat.user) }}님의 소개팅 만남이<br />어떻게 되었는지 응답해주세요!
            </h2>

            <button
                class="btn btn-brd"
                :class="{ active: btn.active }"
                v-for="btn in buttons"
                :key="btn.key"
                @click="onClick(btn)"
            >
                <p class="label" v-html="btn.label" />
                <p v-if="btn.detail" class="f-regular f-13 m-t-4">{{ btn.detail }}</p>
            </button>
        </section>
    </div>
</template>

<script>
import StackRouterHeaderBar from '@/components/app/StackRouterHeaderBar.vue'
import refundService from '@/services/refund'
import realFriendService from '@/services/realfriend-meeting'

export default {
    name: 'RealFriendMeetingRefundCheckPage',
    components: { StackRouterHeaderBar },
    props: {
        chat: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        buttons: [],
        refund: null,
        introduceRequest: null,
    }),
    computed: {
        introduce() {
            return this.chat.introduce || {}
        },
    },
    methods: {
        async init() {
            this.buttons = [
                {
                    key: 'refund',
                    label: '안 만났고, 안 만날 예정이에요',
                    detail: '친구의 사정, 변심 등',
                },
                {
                    key: 'reject',
                    label: `안 만났지만, ${this.$nameOrNick(this.chat.user)}님에게<br />귀책 사유(잘못)가 있어요`,
                },
                {
                    key: 'willMeet',
                    label: '안 만났지만, 만날 예정이에요',
                },
            ]

            this.buttons.forEach(btn => {
                this.$set(btn, 'active', false)
            })

            const refunds = await refundService.refundIntroduce(this.chat.id)
            const myIntroduces = await realFriendService.mine()
            myIntroduces.find(i => i.id === this.introduce.id)
            this.refund = refunds[0]
        },
        onClick(btn) {
            btn.active = !btn.active

            if (btn.active) {
                setTimeout(() => this.confirm(btn), 300)
            }
        },
        confirm(btn) {
            try {
                this.$loading(true)
                switch (btn.key) {
                    case 'refund':
                        this.$modal
                            .basic({
                                title: '소개팅권 환급',
                                body: `친구와 소개팅을 갖지 않았음을 확인합니다. ${this.$nameOrNick(
                                    this.chat.user,
                                )}님에게 소개팅권이 환급됩니다.`,
                                buttons: [
                                    {
                                        label: 'CANCEL',
                                    },
                                    {
                                        label: 'CONFIRM',
                                        class: 'c-primary',
                                    },
                                ],
                            })
                            .then(idx => {
                                if (idx === 1) {
                                    this.confirmRefund(1)
                                }
                            })

                        break
                    case 'reject':
                        this.$stackRouter.push({
                            name: 'RealFriendMeetingRefundRejectPage',
                            props: {
                                introduce: this.introduce,
                            },
                        })
                        break
                    case 'willMeet':
                        this.$modal
                            .basic({
                                body: '만날 예정인지 친구에게 확인하셨나요?',
                                buttons: [
                                    {
                                        label: 'CANCEL',
                                    },
                                    {
                                        label: '확인했어요',
                                        class: 'c-primary',
                                    },
                                ],
                            })
                            .then(idx => {
                                if (idx === 1) {
                                    this.confirmRefund(2)
                                }
                            })
                        break
                }
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        async confirmRefund(status) {
            try {
                const { msg } = await realFriendService.confirmRefund(this.introduce.id, this.introduce.request.id, {
                    refund_status: status,
                })

                const chat = { ...this.chat }
                chat.introduce.request.accept_status = status === 1 ? 'refund_accept' : 'refund_reject'
                this.$store.commit('updateChatAndChats', chat)

                this.$toast.success(msg)
                this.$stackRouter.pop()
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        onClickImage(img) {
            this.$modal.images({
                images: [img],
            })
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.real-friend-meeting-refund-check {
    .title {
        color: black;
        font-weight: 500;

        @include f-medium;
    }

    .section {
        padding: 40px 16px;
    }

    .hr {
        border-bottom: solid 1px $grey-02;
    }

    .refund {
        &-detail {
            padding: 20px 16px;
            background-color: $grey-01;
            border-radius: 12px;
        }

        .grid-col {
            width: 100%;
            gap: 8px;

            $img-size: calc((100vw - 8px - 64px) / 2);

            .img {
                width: $img-size;
                height: $img-size;
                border-radius: 8px;
                border: solid 1px $grey-03;
                background-size: cover !important;
            }
        }
    }

    .btn {
        padding: 12px 16px;
        width: 100%;
        height: auto;
        flex-direction: column;
        align-items: flex-start;

        &.active {
            border-color: $purple-primary;

            .label {
                color: $purple-primary;
            }
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        .label {
            text-align: start;

            @include f-medium;
        }
    }
}
</style>
